<template>
  <TopNavBar />

  <!-- breadcrumb area start -->
  <div
    class="breadcrumb-area"
    style="background-image: url(assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">Data visualisation</h1>
            <ul class="page-list">
              <li>
                <router-link class="nav-link" :to="{ name: 'home' }"
                  >Home</router-link
                >
              </li>
              <li>Data Visualisation</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb area End -->

  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="img-with-video">
            <div class="img-wrap">
              <img src="assets/img/work-processing/5.png" alt="video" />
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title"><span>Interactive</span> dashboards</h2>
              <p class="s-animate-2">
                Intuitively from data toinsights — by yourself or in teams
              </p>
              <p>
                Our industry experts have a track record designing dashboards
                that are easy to interact with — not only for savvy analysts but
                also for CCOs and CEOs.
              </p>

              <p>
                We believe there should be no monopoly on knowledge within the
                organisation, and we are convinced that our tools facilitate
                effective meetings across different organisational levels.
              </p>

              <p>
                Our platform offers quick and easy interaction with the
                underlying data, with relevant tables, maps and charts that
                bring the data to life. Let us do the legwork, so you can focus
                on the brainwork.
              </p>

              <p>
                Interested? We would be pleased to demo our offering to you.
              </p>
            </div>
            <div class="text-center">
              <a
                class="btn btn-radius btn-blue s-animate-3 mt-3"
                href="mailto:info@fareplay.aero"
                ><i class="fa fa-paper-plane"></i> Contact us</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <LetsTalk />
  <Footer />
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "DataVisulaisation",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
};
</script>